@import "./variables.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: "Poppins", sans-serif !important;
}

.login-wrap__right__container {
  .auth-container {
    @apply m-0 w-full border-none shadow-none #{!important};
    .okta-sign-in-header {
      @apply hidden;
    }
    h2 {
      @apply text-black font-bold text-7xl text-left #{!important};
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

.dashboard-error {
  @apply p-5 box-border rounded-lg border border-grey bg-white;
  box-shadow: 0px 0px 2px rgba(14, 31, 53, 0.12);
  h3 {
    @apply text-lg leading-5 font-bold mt-1.5 text-charcoal-grey;
  }
}

#clientScopeApp {
  @apply text-lg text-charcoal-grey;
}

.no-result {
  @apply min-h-4 inline-block;
}

.breadcrumb,
.portfolio-title,
.series-title,
.holding-title {
  @apply min-h-5;
}

.snap-shot {
  button {
    @apply border text-smd rounded-md py-1 px-2.5 mx-0 my-2.5 font-bold;
  }
}

.investorIds {
  @apply overflow-hidden relative;
  &__ddl {
    @apply text-md;
  }
}

.main-content {
  height: calc(100vh - 72px);
}

.custom-breadcrumb {
  @apply flex;
  &__text {
    @apply text-smd cursor-pointer text-primary not-italic whitespace-normal;
    &:hover {
      @apply text-secondary underline;
    }
  }
  &__icon {
    @apply text-xl ml-2 mt-0.5;
  }
}

.breadcrumb-dropdown {
  @apply relative;
  &::after {
    @apply text-smd pointer-events-none absolute text-charcoal-grey right-0 top-[47%];
    content: "";
    z-index: 1;
    transform: translate(0, -50%);
    border-top: 0.6em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
  }
}

.hiddenBreadcrumb {
  @apply text-smd invisible;
}

.widget-card {
  @apply m-0 w-full border border-light-grey box-border rounded-lg p-0 h-full min-h-[450px];
  box-shadow:
    0px 0px 2px rgb(14 31 53 / 12%),
    0px 1px 4px rgb(14 31 53 / 6%);
}

.remaining-value-text {
  @apply block text-right text-sm text-grey mb-4 mr-4;
}

.highcharts-container {
  width: 100% !important;
}

.export-csv-btn {
  @apply border border-primary p-2 rounded text-smd font-bold;
  .export-icon {
    @apply text-xl mb-[-10px] text-primary;
  }
  &:hover {
    @apply border border-cyan-blue text-cyan-blue;
  }
}

.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mdc-tooltip {
      &.blue-tooltip {
        .mdc-tooltip__surface {
          @apply py-2 px-3;
          font-size: 11px;
          max-width: 150px !important;
        }
        &-xl {
          .mdc-tooltip__surface {
            @apply text-md py-5 px-2.5;
            max-width: 225px !important;
          }
        }
      }
      &.blue-tooltip,
      &.blue-tooltip-xl {
        .mdc-tooltip__surface {
          @apply text-white;
          background-color: rgba(0, 36, 81, 0.8);
        }
      }
      .mdc-tooltip__surface {
        @apply bg-white p-1.5 text-sm text-charcoal-grey border border-grey;
        box-shadow: 0px 0px 2px rgb(14 31 53 / 12%);
        max-width: 150px !important;
      }
    }
  }
}

.widget-card {
  .mat-mdc-tab-label-container {
    border-bottom: 1px solid #dee2e6;
    padding: 0 15px;
    .mdc-tab {
      @apply text-secondary text-md opacity-100 tracking-normal;
      &.mat-mdc-tab-disabled {
        @apply justify-start pl-1.5 cursor-default;
        &:hover {
          .mat-mdc-tab-ripple {
            @apply bg-white;
          }
        }
        .mdc-tab__text-label {
          @apply font-bold;
        }
      }
      &.mdc-tab--active {
        @apply font-bold;
      }
      .mdc-tab-indicator {
        .mdc-tab-indicator__content--underline {
          @apply border border-secondary max-w-[165px] #{!important};
        }
      }
      .mdc-tab__text-label {
        @apply text-secondary text-md opacity-100 #{!important};
      }
    }
  }
  .mat-mdc-tab-group {
    @apply h-full;
    .mat-mdc-tab-body-wrapper {
      @apply h-full;
    }
  }
}

.width-3 {
  width: 3%;
}
.width-6 {
  width: 6%;
}
.width-7 {
  width: 7%;
}
.width-8 {
  width: 8%;
}
.width-9 {
  width: 9%;
}
.width-10 {
  width: 10%;
}
.width-12 {
  width: 12%;
}
.width-13 {
  width: 13%;
}
.width-14 {
  width: 14%;
}
.width-16 {
  width: 16%;
}
.width-18 {
  width: 18%;
}
.width-20 {
  width: 20%;
}
.width-21 {
  width: 21%;
}
.width-23 {
  width: 23%;
}
.width-27 {
  width: 27%;
}
.width-32 {
  width: 32%;
}
.width-35 {
  width: 35%;
}
.width-36 {
  width: 36%;
}
.width-37 {
  width: 37%;
}
.width-50 {
  width: 50%;
}
.width-120 {
  width: 120px;
}
.width-140 {
  width: 140px;
}
.width-160 {
  width: 160px;
}
.width-205 {
  width: 205px;
}
.width-245 {
  width: 245px;
}
.width-305 {
  width: 305px;
}
.width-580 {
  width: 580px;
}
.width-885 {
  width: 885px;
}
.width-3300 {
  max-width: 3300px;
}

.series-table-error {
  .error-message {
    @apply bg-white p-2 text-error font-semibold rounded-md;
  }
}

.card-header {
  @apply bg-white text-md font-bold text-secondary;
  border-radius: 8px 8px 0px 0px;
}

.mln-15 {
  margin-left: -15px;
}

.container-fluid {
  padding-left: 8.5rem;
}

.title-row {
  .table-title {
    @apply text-2xl font-bold text-charcoal-grey;
  }
  .since-inception-text {
    @apply text-md text-grey italic font-extralight;
  }
}

.expanded-export {
  @apply flex justify-end items-baseline;
}

.no-result-text {
  @apply flex items-center justify-center;
}

.mat-sort-header {
  &.text-right {
    .mat-sort-header-content {
      text-align: right;
    }
    .mat-sort-header-container {
      @apply justify-end;
    }
  }
  &.text-left {
    &.sub-header {
      @apply pl-1.5;
    }
    .mat-sort-header-content {
      text-align: left;
    }
    .mat-sort-header-container {
      @apply justify-start;
    }
  }
}

.expand-collapse-icon-holding {
  @apply cursor-pointer w-4 h-4;
}

.deal-name-div {
  @apply flex justify-between;
}

.mat-mdc-table .mdc-data-table__row {
  height: auto;
  min-height: 43px;
}

.border-top-0 {
  border-top: 0;
}

th.mat-mdc-header-cell {
  @apply align-middle text-center;
}

.pointer {
  cursor: pointer;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  overflow-wrap: anywhere;
}

.pb-14 {
  padding-bottom: 14px;
}

.left {
  @apply float-left relative;
}

.byTextSelect {
  @apply flex justify-end;
  select#byValueTextSel {
    @apply border-none;
    option {
      @apply text-left;
    }
  }
}

.btn:disabled {
  opacity: 0.45;
}

img {
  display: inline;
}

.key-performance-sm {
  .mat-mdc-tab-labels {
    flex-wrap: wrap;
    .mat-mdc-tab-disabled {
      margin-right: 1em;
    }
  }
}

.ngx-ui-tour_backdrop {
  background-color: rgb(35 47 74 / 70%) !important;
}

.tour-popup-wrapper {
  max-width: fit-content !important;
  @apply w-fit my-2.5 mx-0 #{!important};
  .mat-mdc-card {
    max-width: min-content !important;
    @apply bg-cyan-blue rounded;
    .mat-mdc-button {
      @apply text-white bg-secondary;
    }
    .prev {
      @apply text-black;
      background-color: transparent;
    }
    .next {
      @apply ml-auto;
    }
    .mat-mdc-button[disabled] {
      @apply hidden;
    }
    .mat-mdc-card-actions {
      @apply flex justify-between;
      button:first-child {
        mat-icon {
          @apply mr-1;
        }
      }
      button:last-child {
        mat-icon {
          @apply ml-1;
        }
      }
    }
  }
}

.tour-icon {
  @apply h-8 w-8 bg-no-repeat;
  background-image: url("./assets/images/tour.png");
}

.series-holdings-card {
  .mat-mdc-header-cell {
    min-width: 200px;
    &:last-child {
      min-width: auto !important;
    }
  }
}

.shadow-background,
.normal-background {
  @apply transition-all duration-500;
}

.shadow-background {
  @apply bg-grey;
}

.sticky-header {
  @apply bg-white sticky left-0;
  z-index: 1;
}

.email-link {
  a {
    @apply text-white;
  }
}

.detail-card {
  @apply p-5 box-border border border-light-grey rounded-lg mb-5;
  box-shadow:
    0px 8px 20px rgb(14 31 53 / 12%),
    0px 1px 4px rgb(14 31 53 / 6%);
}

.normal-select-text {
  @apply text-black;
}

.click-here-btn {
  @apply font-bold cursor-pointer;
  &:hover {
    @apply text-secondary;
  }
}

.final {
  @apply text-charcoal-grey italic pl-1 font-normal;
}

.breadcrumbAsOfDate {
  @apply w-auto font-normal text-sm text-charcoal-grey;
}

// filter selection - material css updates
.mat-mdc-form-field-infix {
  padding: 0.35em 0em !important;
  min-height: auto !important;
  .mat-mdc-select-placeholder,
  .mat-mdc-input-element,
  .mat-mdc-select-value-text {
    font-size: 0.9rem;
  }
}
.mat-datepicker-popup {
  margin-left: 13.1em;
  margin-top: 10px;
}

.mat-mdc-option {
  font-size: 0.9rem;
}

.mat-mdc-checkbox-checked {
  .mdc-form-field {
    @apply text-secondary;
  }
  .mdc-checkbox__background {
    @apply border border-secondary bg-secondary #{!important};
  }
}

.mdc-list-item--selected {
  .mdc-list-item__primary-text {
    @apply text-secondary #{!important};
  }
  .mat-pseudo-checkbox-checked {
    @apply bg-secondary #{!important};
  }
}

.mat-mdc-option.mat-mdc-option-active {
  @apply bg-white;
}

.select-all-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer;
  label,
  .mdc-form-field {
    @apply w-full;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: 5px !important;
}

.mat-select-investors,
.mat-select-portfolios {
  min-width: 160%;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  @apply bg-white #{!important};
}

.as-of-date {
  .mat-mdc-form-field-infix {
    padding: 0.2em 0em !important;
    min-height: auto !important;
    max-width: 100px !important;
    .mat-mdc-select-placeholder,
    .mat-mdc-input-element,
    .mat-mdc-select-value-text {
      font-size: 12px !important;
      font-weight: 700 !important;
    }
  }
  &.error-text {
    .mat-mdc-select-value-text {
      @apply text-error;
    }
  }
}

.as-of-date-panel {
  span.mdc-list-item__primary-text {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  .mat-mdc-option {
    min-height: 26px !important;
  }
  &.mat-mdc-select-panel {
    @apply bg-white #{!important};
  }
  &.error-text {
    @apply text-error;
  }
}

.table-irr-value,
.table-tvpi-value {
  @apply text-dark-grey mt-2.5 ml-1.5 font-bold;
}

.table-irr-text,
.table-tvpi-text {
  @apply text-label-color p-[0.5px] mt-[0.8px] mr-0 mb-1 ml-1;
}

.expand-collapse-icon-series {
  @apply sticky cursor-pointer w-4 h-4 left-[50%] z-[102];
}

.highcharts-legend {
  pointer-events: none;
}

.tour-completed-dialog {
  .whitespace-break-spaces {
    @apply flex;
  }
}

.information-dialog {
  @apply min-w-[800px] min-h-[300px];
}

.filter-form-field {
  .mat-mdc-form-field-infix {
    @apply flex w-[110%];
  }
  .mat-mdc-text-field-wrapper {
    @apply p-0;
  }
}

.navbar {
  @apply py-8 px-0 #{!important};
}

.status-chip {
  @apply rounded-full text-md font-semibold px-3 py-px;
  &.on-plan {
    @apply text-information-text bg-background-color-on-plan;
  }
  &.below-plan {
    @apply text-error-text bg-background-color-below-plan;
  }
  &.above-plan {
    @apply text-success-text bg-background-color-above-plan;
  }
  &.unknown {
    @apply bg-background-color-light-grey;
  }
}
